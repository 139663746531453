<template>
  <header class="header-container">
    <div class="header-nav container">
      <div class="header-row">
        <!-- <img src="/src/assets/images/default/logo.svg"> -->
        <img :src="require('@/assets/images/default/logo.svg')">

        <div class="socials">
          <a href="https://github.com/Arash-Ghandi">
            <i class="fab fa-github"></i>
          </a>
          <a href="https://linkedin.com/in/arash-ghandi">
            <i class="fab fa-linkedin"></i>
          </a>
          <a href="https://www.xing.com/profile/Arash_Ghandi">
            <i class="fab fa-xing"></i>
          </a>
        </div>

      </div>
    </div>
  </header>
</template>
<style scoped>
.header-container{
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 30;
  width: 100%;
  padding-left: 128px;
  padding-right: 128px;

}
.header-nav{
  margin-right: auto;
  margin-left: auto;
}
.header-row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.header-nav img{
  width: 210px;
  height: 60px;
}
.socials{
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-size: 1.125rem;
}
.socials a:hover{
  color: rgb(153, 74, 74);
  transition: all 300ms ease-in-out;
}
.socials a{
    color: white;
}
@media (min-width: 1024px) {
  .header-row{
    flex-direction: row;
  }
}
@media (min-width: 1200px) {
  .header-container{
    padding-left: 0;
    padding-right: 0;
    height: 90px;
  }
}
</style>
