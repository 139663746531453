<template class="container">
<div class="bg-c-blue-dark w-100 h-100 container-fluid text-white p-0 position-relative">
    <BgTopLeftImage />
    <BgBottomRightImage />
    
    

    <transition appear enter-active-class="animated fadeInRight">
      <Navbar />
    </transition>
    <transition appear enter-active-class="animated fadeInDown">
      <Header />
    </transition>
    <RouterView />


  </div>
</template>
<script>
import BgTopLeftImage from './components/Shared/BgTopLeftImage'
import BgBottomRightImage from './components/Shared/BgBottomRightImage'
import Navbar from './components/Shared/Navbar'
import Header from './components/Shared/Header'

export default {
  components : {
    BgTopLeftImage,
    BgBottomRightImage,
    Navbar,
    Header
  }
}
</script>

