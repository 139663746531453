<template>
  <section class="bg-image-tl-section">
    <div class="bg-image-tl">
    </div>
  </section>
</template>
<style scoped>
.bg-image-tl-section{
  position: absolute;
  height: 100%;

}
.bg-image-tl{
  background: none;
  background-image: url('/src/assets/images/default/top-left-img.png');
  width: 400px;
  height: 400px;
  left: 0;
  top:0;
  opacity: 25%;
  z-index:3;
}
</style>
