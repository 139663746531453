<template>
  <section class="section-bg-image-br">
    <div class="bg-image-br">
    </div>
  </section>
</template>
<style scoped>
.section-bg-image-br{
  width: 1200px;
  height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.bg-image-br{
  background: none;
  height: 100%;
  width: 100%;
  position: absolute;
  mix-blend-mode: color-dodge;
  transform: translateZ(0);
}
@media (min-width: 1280px) {
  .bg-image-br{
    background-image: url('/src/assets/images/default/bg-explosion.png');
    background-position: right;
    background-size: cover;
    background-repeat: no-repeat;
    animation: pulse 23000ms infinite;
}
}
</style>
