<template>
<nav class="nav-continer justify-content-lg-center">
    <div class="main-menu w-100 px-4">
      <router-link class="nav-link" active-class="active" to="/about" exact>
          <i class="fas fa-user"></i>
          <div class="pop-div">
            <div class="pop-title">
              <span class="pop-m">
                About
              </span>
              <span class="pop-a"></span>
            </div>
          </div>
      </router-link>
      <router-link class="nav-link" active-class="active" to="/" exact>
          <i class="fas fa-home"></i>
          <div class="pop-div">
            <div class="pop-title">
              <span class="pop-m">
                Home
              </span>
              <span class="pop-a"></span>
            </div>
          </div>
      </router-link>
      <router-link class="nav-link" active-class="active" to="/services" exact>
          <i class="fas fa-th-list"></i>
          <div class="pop-div">
            <div class="pop-title">
              <span class="pop-m">
                Services
              </span>
              <span class="pop-a"></span>
            </div>
          </div>
      </router-link>
      <!-- <router-link class="nav-link" active-class="active" to="/projects" exact>
          <i class="fas fa-briefcase"></i>
          <div class="pop-div">
            <div class="pop-title">
              <span class="pop-m">
                Projects
              </span>
              <span class="pop-a"></span>
            </div>
          </div>
      </router-link>
      <router-link class="nav-link" active-class="active" to="/contact" exact>
          <i class="fas fa-envelope"></i>
          <div class="pop-div">
            <div class="pop-title">
              <span class="pop-m">
                Contact
              </span>
              <span class="pop-a"></span>
            </div>
          </div>
      </router-link> -->
    </div>
  </nav>
</template>
<style scoped>
.pop-div{
  position: absolute;
  padding-right: 3.5rem;
  right: 0;
  display: none;
}

.pop-title{
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px;
  border-radius: 3px;
}
.pop-title .pop-m{
  font-size: 12px;
  line-height: 1;
  text-transform: capitalize;
}
.pop-title .pop-a{
  border: solid;
  border-left-color: #ffffff;
  border-left-width: 8px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-top-width: 6px;
  border-bottom-width: 6px;
  border-right-width: 0;
  position: absolute;
  right: -0.5rem;
}

.main-menu a{
    color: white;
    position: relative;
    display: flex;
    align-items: center;

}
.main-menu a:hover{
  color: rgb(153, 74, 74);
  transition: all 300ms ease-in-out;
}
a.active{
  color: rgb(153, 74, 74);
  /* font-size: 1.5rem; */
}
.nav-continer{
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1rem;
  position: fixed;
  height: max-content;
  bottom: 0;
  margin-top: auto;
  z-index: 50;
  top: 0;
  width: 100%;
}
.main-menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(254, 202, 202, 0.1);
  height: 65px;
  row-gap: 2.5rem;
  backdrop-filter: blur(4px);
  font-size: 1.875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

@media (min-width: 768px) {
  .main-menu{
    padding-left: 10rem;
    padding-right: 10rem;
  }
}
@media (min-width: 1280px) {
  .main-menu a:hover .pop-div{
    display: flex;
  }
  .nav-continer{
    justify-content: center;
    right: 2%;
    width: 4rem;
    max-width: 28rem;
    height: 100vh !important;
  }
  .main-menu{
    flex-direction: column;
    justify-content: center;
    font-size: 1.25rem;
    border-radius: 9999px;
    padding-left: 0;
    padding-right: 0;
    height: max-content;
  }
}
</style>
