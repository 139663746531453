import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/tools/fontawesome/css/all.min.css'
import './assets/css/animate.css'
import './assets/css/style.css'
import 'bootstrap/dist/js/bootstrap.bundle'

const app =  createApp(App)
app.use(router)
app.mount('#app')
